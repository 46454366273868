export default [
  {
    path: 'home',
    name: 'Home',
    component: () => import('@/views/home'),
    meta: {
      title: '尊创科技-联盟服务',
      tabBar: true,
      keepAlive: true
    }
  }
]
