export default [
  {
    path: 'pdf/:url',
    name: 'Pdf',
    component: () => import('@/views/pdf'),
    meta: {
      title: '尊创科技-联盟服务',
      tabBar: false,
      keepAlive: false
    }
  }
]
