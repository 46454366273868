import ParentView from '@/layouts/parentView'
import store from '@/store'
import constant from '@/constant'
export default [
  {
    path: 'certificate',
    name: 'Certificate',
    component: ParentView,
    redirect: { name: 'CertificateQuery' },
    beforeEnter: (to, from, next) => {
      if ([constant.verifyStatus.CHECK.value, constant.verifyStatus.WAITING.value].includes(store.getters['user/status'])) {
        next()
      } else {
        next({ name: 'Home' })
      }
    },
    children: [
      {
        path: 'query',
        name: 'CertificateQuery',
        component: () => import('@/views/certificate'),
        meta: {
          title: '尊创传媒',
          tabBar: false,
          keepAlive: true
        }
      },
      {
        path: 'catalogue/:type',
        name: 'CertificateCatalogue',
        component: () => import('@/views/certificate/catalogue'),
        meta: {
          title: '尊创传媒',
          tabBar: false,
          keepAlive: true
        }
      },
      {
        path: 'detail/:id',
        name: 'CertificateDetail',
        component: () => import('@/views/certificate/detail'),
        meta: {
          title: '尊创传媒',
          tabBar: false,
          keepAlive: false
        }
      }
    ]
  }
]
