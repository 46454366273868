<template>
  <keep-alive :include="cacheList">
    <router-view :key="name" />
  </keep-alive>
</template>
<script>
export default {
  name: 'ParentView',
  data () {
    return {
      name: ''
    }
  },
  watch: {
    $route: {
      handler () {
        this.routerKey = this.$route.fullPath
      },
      immediate: true
    }
  },
  computed: {
    cacheList () {
      const _this = this
      let getCacheMenu = list => {
        let tempList = []
        for (let i in list) {
          if (list[i].meta && list[i].meta.keepAlive) {
            tempList.push(list[i].name)
          }
          if (list[i].children && list[i].children.length > 0) {
            tempList = [...tempList, ...getCacheMenu(list[i].children)]
          }
        }
        return tempList
      }
      const list = [...getCacheMenu((_this.$router && _this.$router.options && _this.$router.options.routes) ? _this.$router.options.routes : [])]
      if (_this.$route.meta && _this.$route.meta.keepAlive) {
        list.unshift('ParentView')
      }
      return list
    }
  },
  created () {
    const _this = this
    _this.name = _this.$route.path
  }
}
</script>
