import home from './module/home'
import user from './module/user'
import notice from './module/notice'
import iframe from './module/iframe'
import pdf from './module/pdf'
import news from './module/news'
import certificate from './module/certificate'
import Main from '@/layouts/main'

export default [{
  path: '/',
  name: 'main',
  component: Main,
  redirect: { name: 'Home' },
  children: [
    ...home,
    ...user,
    ...notice,
    ...iframe,
    ...pdf,
    ...news,
    ...certificate
  ]
}]
