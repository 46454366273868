import { mapGetters, mapActions } from 'vuex'
import constant from '@/constant'
import { isEmpty } from '@/utils/tools'
const mixin = {
  data () {
    return {
      GLOBAL_CONSTANT: constant
    }
  },
  computed: {
    ...mapGetters({
      GLOBAL_USER_DATA: 'user/userInfo',
      GLOBAL_REFRESH_NAV: 'app/refreshNav',
      GLOBAL_PRE_ROUTE: 'app/preRoute'
    })
  },
  methods: {
    ...mapActions({
      setRefreshNav: 'app/setRefreshNav',
      clearRefreshNav: 'app/clearRefreshNav',
      wxShare: 'user/wxShare'
    }),
    handleGlobalBack () {
      const _this = this
      if (_this.GLOBAL_PRE_ROUTE && !isEmpty(_this.GLOBAL_PRE_ROUTE.name)) {
        _this.$router.back()
      } else {
        _this.$router.push({ name: 'Home'})
      }
    }
  }
}

export default mixin
