import axios from 'axios'
import {
  baseURL,
  contentType,
  requestTimeout,
  successCode,
  statusName,
  messageName
} from '@/config'
import store from '@/store'
import { Toast } from 'vant'
import { updateTokenTime } from '@/utils/token'

const hiddenToastArray = ['/api/user/info', '/api/app/wechatSignPackage', '/api/wechat/wxcode']

let toast
let requestQueue = {}
const handleData = async ({ config, data, status = 0, statusText }) => {
  delete requestQueue[config.url]
  if (toast && !Object.keys(requestQueue).length) {
    toast.clear()
  }
  // 若data.code存在，覆盖默认code
  let code = 0
  code = data && data[statusName] ? data[statusName] : status
  // 若code属于操作正常code，则code修正为200
  if (successCode.includes(code)) {
    return data
  }
  // 异常处理
  // 若data.msg存在，覆盖默认提醒消息
  const errMsg = `${
    data && data[messageName]
      ? data[messageName]
      : statusText
  }`
  Toast(errMsg)
  return Promise.reject(data)
}

const instance = axios.create({
  baseURL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType
  }
})

instance.interceptors.request.use(
  (config) => {
    const token = store.getters['user/token']
    if (token) config.headers.Authorization = token
    if (!hiddenToastArray.includes(config.url) && !config.hiddenToast) {
      requestQueue[config.url] = true
    }
    if (Object.keys(requestQueue).length) {
      if (!toast || !toast.value) {
        toast = Toast.loading({
          overlay: true,
          message: '加载中...',
          duration: 0
        })
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    updateTokenTime()
    return handleData(response)
  },
  (error) => {
    const { response = {} } = error
    return handleData(response)
  }
)

export default instance
