import { isEmpty } from '@/utils/tools'

const state = () => ({
  /**
   * 刷新页面
   */
  refreshNav: {},
  preRoute: null
})

const getters = {
  refreshNav: (state) => state.refreshNav,
  preRoute: state => state.preRoute
}

const mutations = {
  setRefreshNav (state, value) {
    if (typeof value === 'string') {
      state.refreshNav[value] = true
    } else {
      state.refreshNav[value.name] = isEmpty(value.data) ? true : value.data
    }
  },
  clearRefreshNav (state, name) {
    delete state.refreshNav[name]
  },
  setPreRoute (state, route) {
    state.preRoute = route
  }
}

const actions = {
  setRefreshNav ({ commit }, value) {
    commit('setRefreshNav', value)
  },
  clearRefreshNav ({ commit }, name) {
    commit('clearRefreshNav', name)
  },
  setPreRoute ({ commit }, route) {
    commit('setPreRoute', route)
  }
}
export default { state, getters, mutations, actions }
