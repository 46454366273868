import { storage, tokenName } from '@/config'
import { isEmpty } from '@/utils/tools'
import cookie from 'js-cookie'

/**
 * @description 获取token
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getToken () {
  const expireTime = (40 * 24 - 2) * 60 * 60 * 1000 // token到期时间 40天(减2小时)
  let tokenStr = null
  if (storage) {
    if (storage === 'localStorage') {
      tokenStr = localStorage.getItem(tokenName)
    } else if (storage === 'sessionStorage') {
      tokenStr = sessionStorage.getItem(tokenName)
    } else if (storage === 'cookie') {
      tokenStr = cookie.get(tokenName)
    } else {
      tokenStr = localStorage.getItem(tokenName)
    }
  } else {
    tokenStr = localStorage.getItem(tokenName)
  }
  if (isEmpty(tokenStr)) {
    return ''
  }
  try {
    let tokenData = JSON.parse(tokenStr)
    if (new Date().getTime() - tokenData.time < expireTime) {
      return tokenData.value
    } else {
      removeToken()
      return ''
    }
  } catch (err) {
    return tokenStr
  }
}

/**
 * @description 存储token
 * @param token
 * @returns {void|*}
 */
export function setToken (token) {
  const tokenData = {
    value: token,
    time: new Date().getTime()
  }
  let tempSaveData = JSON.stringify(tokenData)
  if (storage) {
    if (storage === 'localStorage') {
      return localStorage.setItem(tokenName, tempSaveData)
    } else if (storage === 'sessionStorage') {
      return sessionStorage.setItem(tokenName, tempSaveData)
    } else if (storage === 'cookie') {
      return cookie.set(tokenName, tempSaveData)
    } else {
      return localStorage.setItem(tokenName, tempSaveData)
    }
  } else {
    return localStorage.setItem(tokenName, tempSaveData)
  }
}

/**
 * 更新到期时间
 */
export const updateTokenTime = () => {
  let tempToken = getToken()
  setToken(tempToken)
}

/**
 * @description 移除token
 * @returns {void|Promise<void>}
 */
export function removeToken () {
  if (storage) {
    if (storage === 'localStorage') {
      return localStorage.removeItem(tokenName)
    } else if (storage === 'sessionStorage') {
      return sessionStorage.removeItem(tokenName)
    } else if (storage === 'cookie') {
      return cookie.remove(tokenName)
    } else {
      return localStorage.removeItem(tokenName)
    }
  } else {
    return localStorage.removeItem(tokenName)
  }
}
