import { constantFormat } from '@/utils/tools'

/**
 * 性别
 * @type {{UNKNOWN: {text: string, value: number}, MEN: {text: string, value: number}, WOMEN: {text: string, value: number}}}
 */
const sexType = {
  UNKNOWN: { text: '未知', value: 0, keyword: 'UNKNOWN' },
  MEN: { text: '男', value: 1, keyword: 'MEN' },
  WOMEN: { text: '女', value: 2, keyword: 'WOMEN' }
}

/**
 * 审核状态
 * @type {{BEFORE: {text: string, keyword: string, value: number}, WAITING: {text: string, keyword: string, value: number}, CHECK: {text: string, keyword: string, value: number}, REFUSE: {text: string, keyword: string, value: number}}}
 */
const verifyStatus = {
  BEFORE: { text: '未申请', value: 0, keyword: 'BEFORE' },
  WAITING: { text: '自动通过', value: 1, keyword: 'WAITING' },
  CHECK: { text: '审核通过', value: 2, keyword: 'CHECK' },
  REFUSE: { text: '审核未通过', value: 3, keyword: 'REFUSE' },
  STOP: { text: '审核终止', value: 4, keyword: 'STOP' }
}

/**
 * 案例类型
 * @type {{PARTY: {text: string, keyword: string, value: number}, SCHOOL: {text: string, keyword: string, value: number}, MILITARY: {text: string, keyword: string, value: number}, ENTERPRISE: {text: string, keyword: string, value: number}, HOSPITAL: {text: string, keyword: string, value: number}}}
 */
const caseType = {
  PARTY: { text: '党政机关/市政景观项目', value: 1, keyword: 'PARTY' },
  SCHOOL: { text: '校园文化建设项目', value: 2, keyword: 'SCHOOL' },
  MILITARY: { text: '军警项目', value: 3, keyword: 'MILITARY' },
  HOSPITAL: { text: '医院项目', value: 4, keyword: 'HOSPITAL' },
  ENTERPRISE: { text: '央国企项目', value: 5, keyword: 'ENTERPRISE' }
}

/**
 * 证书类型
 * @type {{HONOR: {text: string, keyword: string, value: number}, QUALIFICATION: {text: string, keyword: string, value: number}}}
 */
const certificateType = {
  QUALIFICATION: { text: '资质和专利', value: 11, keyword: 'QUALIFICATION' },
  HONOR: { text: '荣誉和证书', value: 12, keyword: 'HONOR' }
}

export default {
  ...constantFormat('sexType', sexType),
  ...constantFormat('verifyStatus', verifyStatus),
  ...constantFormat('caseType', caseType),
  ...constantFormat('certificateType', certificateType)
}
