import ParentView from '@/layouts/parentView'
import store from '@/store'
import constant from '@/constant'
export default [
  {
    path: 'notice',
    name: 'Notice',
    component: ParentView,
    beforeEnter: (to, from, next) => {
      if (to.query._sources === 'share') {
        if (store.getters['user/status'] === constant.verifyStatus.CHECK.value) {
          next()
        } else {
          next({ name: 'Home' })
        }
      } else {
        next()
      }
    },
    children: [
      {
        path: 'detail/:id',
        name: 'NoticeDetail',
        component: () => import('@/views/notice/detail'),
        meta: {
          title: '尊创科技-联盟服务',
          tabBar: false,
          keepAlive: true
        }
      },
      {
        path: 'add',
        name: 'NoticeAdd',
        component: () => import('@/views/notice/add'),
        meta: {
          title: '尊创科技-联盟服务',
          tabBar: false,
          keepAlive: true
        }
      },
      {
        path: 'original/:id',
        name: 'NoticeOriginal',
        component: () => import('@/views/notice/original'),
        meta: {
          title: '尊创科技-联盟服务',
          tabBar: false,
          keepAlive: true
        }
      }
    ]
  }
]
