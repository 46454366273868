import { getToken, setToken, removeToken } from '@/utils/token'
import { getUrlParam, delUrlParam, getHost, addUrlParam } from '@/utils/tools'
import { getUserInfo, loginByCode, wxAuth, getWxConfig } from '@/api/user'
import { Toast } from 'vant'

const platform = navigator.platform

const formatShareUrl = (url = '/home') => {
  let str = ''
  if (url) {
    if (url.indexOf('http') === -1) {
      str = `${getHost()}${url}`
    } else {
      str = url
    }
  } else {
    str = `${getHost()}`
  }
  str = addUrlParam(str, '_source', 'share')
  // return `${getHost()}/redirect.html?app3Redirect=${encodeURIComponent(str)}&_t=${new Date().getTime()}`
  return str
}

const state = () => ({
  hasGetInfo: false,
  hasGetWxConfig: false,
  token: getToken(),
  id: '',
  adminUserId: '',
  lockUserRole: '',
  nickname: '游客',
  avatar: '',
  phone: '',
  companyName: '',
  companyPost: '',
  companyUser: '',
  readNum: 0,
  collectNum: 0,
  status: -1,
  province: '',
  provinceCode: '',
  city: '',
  cityCode: '',
  district: '',
  districtCode: ''
})

const getters = {
  token: (state) => state.token,
  hasGetInfo: state => state.hasGetInfo,
  hasGetWxConfig: state => state.hasGetWxConfig,
  id: (state) => state.id,
  adminUserId: (state) => state.adminUserId,
  lockUserRole: (state) => state.lockUserRole,
  nickname: (state) => state.nickname,
  avatar: (state) => state.avatar,
  phone: (state) => state.phone,
  companyName: (state) => state.companyName,
  companyPost: (state) => state.companyPost,
  companyUser: (state) => state.companyUser,
  readNum: (state) => state.readNum,
  collectNum: (state) => state.collectNum,
  status: (state) => state.status,
  province: (state) => state.province,
  provinceCode: (state) => state.provinceCode,
  city: (state) => state.city,
  cityCode: (state) => state.cityCode,
  district: (state) => state.district,
  districtCode: (state) => state.districtCode,
  userInfo: (state) => {
    return {
      id: state.id,
      adminUserId: state.adminUserId,
      lockUserRole: state.lockUserRole,
      avatar: state.avatar,
      nickname: state.nickname,
      phone: state.phone,
      companyName: state.companyName,
      companyPost: state.companyPost,
      companyUser: state.companyUser,
      readNum: state.readNum,
      collectNum: state.collectNum,
      status: state.status,
      province: state.province,
      provinceCode: state.provinceCode,
      city: state.city,
      cityCode: state.cityCode,
      district: state.district,
      districtCode: state.districtCode
    }
  }
}

const mutations = {
  setToken (state, token) {
    state.token = token
    setToken(token)
  },
  setHasGetInfo (state, status) {
    state.hasGetInfo = status
  },
  setHasGetWxConfig (state, status) {
    state.hasGetWxConfig = status
  },
  setId (state, id) {
    state.id = id
  },
  setAdminUserId (state, adminUserId) {
    state.adminUserId = adminUserId
  },
  setLockUserRole (state, lockUserRole) {
    state.lockUserRole = lockUserRole
  },
  setNickname (state, nickname) {
    state.nickname = nickname
  },
  setAvatar (state, avatar) {
    state.avatar = avatar
  },
  setPhone (state, phone) {
    state.phone = phone
  },
  setCompanyName (state, companyName) {
    state.companyName = companyName
  },
  setCompanyPost (state, companyPost) {
    state.companyPost = companyPost
  },
  setCompanyUser (state, companyUser) {
    state.companyUser = companyUser
  },
  setReadNum (state, readNum) {
    state.readNum = readNum
  },
  setCollectNum (state, collectNum) {
    state.collectNum = collectNum
  },
  setStatus (state, status) {
    state.status = status
  },
  setProvince (state, province) {
    state.province = province
  },
  setProvinceCode (state, provinceCode) {
    state.provinceCode = provinceCode
  },
  setCity (state, city) {
    state.city = city
  },
  setCityCode (state, cityCode) {
    state.cityCode = cityCode
  },
  setDistrict (state, district) {
    state.district = district
  },
  setDistrictCode (state, districtCode) {
    state.districtCode = districtCode
  },
  setAreaCode (state, areaCode) {
    state.areaCode = areaCode
  }
}
const actions = {
  getWxConfig ({ state, commit }, url) {
    return new Promise((resolve, reject) => {
      if (process.env.NODE_ENV !== 'development') {
        let tempWxConfigUrl = url || window.location.href.split('#')[0]
        getWxConfig(encodeURI(decodeURI(tempWxConfigUrl))).then(res => {
          let tempData = res.data
          wx.config({
            debug: false,
            appId: tempData.appId,
            timestamp: tempData.timestamp,
            nonceStr: tempData.nonceStr,
            signature: tempData.signature,
            jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData', 'showAllNonBaseMenuItem', 'hideAllNonBaseMenuItem', 'getLocation', 'onMenuShareTimeline', 'onMenuShareAppMessage']
          })
          wx.error(res => {
            commit('setHasGetWxConfig', false)
            resolve({
              status: 'FAIL'
            })
          })
          wx.ready(() => {
            commit('setHasGetWxConfig', true)
            resolve({
              status: 'SUCCESS'
            })
          })
        }).catch(() => {
          commit('setHasGetWxConfig', false)
          resolve({
            status: 'FAIL'
          })
        })
      } else {
        commit('setHasGetWxConfig', true)
        resolve({
          status: 'SUCCESS'
        })
      }
    })
  },
  async getUserInfo ({ commit, dispatch }) {
    try {
      const {
        data: { id, admin_user_id, lock_user_role, headimgurl, nickname, phone, status, company_name, company_post, company_user, read_num, collect_num, province, province_id, city, city_id, district, district_id }
      } = await getUserInfo()
      if (!id) {
        commit('setHasGetInfo', false)
        await dispatch('resetAll')
        const err = '获取用户信息失败~'
        Toast(err)
        dispatch('wxGrant', () => {
          dispatch('getUserInfo')
        })
      } else {
        commit('setHasGetInfo', true)
        commit('setId', id)
        commit('setAdminUserId', admin_user_id)
        commit('setLockUserRole', lock_user_role)
        commit('setAvatar', headimgurl)
        commit('setNickname', nickname)
        commit('setPhone', phone)
        commit('setCompanyName', company_name)
        commit('setCompanyPost', company_post)
        commit('setCompanyUser', company_user)
        commit('setReadNum', read_num)
        commit('setCollectNum', collect_num)
        commit('setStatus', status)
        commit('setProvince', province)
        commit('setProvinceCode', province_id)
        commit('setCity', city)
        commit('setCityCode', city_id)
        commit('setDistrict', district)
        commit('setDistrictCode', district_id)
      }
    } catch (error) {
      commit('setHasGetInfo', false)
      await dispatch('resetAll')
      dispatch('wxGrant', () => {
        dispatch('getUserInfo')
      })
    }
  },
  async logout ({ dispatch }) {
    await dispatch('resetAll')
  },
  async resetAll ({ commit, dispatch }) {
    commit('setHasGetInfo', false)
    commit('setId', '')
    commit('setAdminUserId', '')
    commit('setLockUserRole', '')
    commit('setAvatar', '')
    commit('setNickname', '')
    commit('setPhone', '')
    commit('setCompanyName', '')
    commit('setCompanyPost', '')
    commit('setCompanyUser', '')
    commit('setReadNum', 0)
    commit('setCollectNum', 0)
    commit('setStatus', -1)
    commit('setProvince', '')
    commit('setCity', '')
    commit('setDistrict', '')
    await dispatch('setToken', '')
    removeToken()
  },
  setToken ({ commit }, token) {
    commit('setToken', token)
  },
  async wxGrant ({ commit }) {
    if (process.env.NODE_ENV === 'development') {
      const { data } = await loginByCode({ code: 666666 })
      commit('setToken', `${data.token_type} ${data.access_token}`)
      return true
    } else {
      if (getUrlParam('code')) {
        const { data } = await loginByCode({ code: getUrlParam('code') })
        let tempLocation = window.location.href
        tempLocation = delUrlParam(tempLocation, 'code')
        tempLocation = delUrlParam(tempLocation, 'state')
        if (data) {
          commit('setToken', `${data.token_type} ${data.access_token}`)
          // history.replaceState({}, '', tempLocation)
          return true
        } else {
          commit('setToken', '')
          const { data } = await wxAuth(tempLocation)
          window.location.replace(data.url)
          return false
        }
      } else {
        const { data } = await wxAuth(window.location.href)
        window.location.replace(data.url)
        return false
      }
    }
  },
  async wxShare ({ state, commit, dispatch }, shareData) {
    if (!state.hasGetWxConfig) {
      await dispatch('getWxConfig')
    }
    wx.ready(async () => {
      if (shareData === undefined) {
        shareData = {
          disabled: false
        }
      }
      let tempShareData = {}
      tempShareData.title = shareData.title ? shareData.title : '尊创科技-联盟服务'
      tempShareData.desc = shareData.desc
      tempShareData.imgUrl = shareData.imgUrl || `${getHost()}/default_share_thumb.png`
      tempShareData.link = formatShareUrl(shareData.link)
      tempShareData.callback = shareData.callback
      tempShareData.shareSuccess = shareData.shareSuccess
      if (shareData.disabled) {
        wx.hideAllNonBaseMenuItem()
        return
      }
      wx.showAllNonBaseMenuItem()
      if (platform.indexOf('Win') === 0) {
        try {
          wx.onMenuShareAppMessage({
            title: tempShareData.title,
            desc: tempShareData.desc,
            link: tempShareData.link,
            imgUrl: tempShareData.imgUrl,
            success: res => {
              if (tempShareData.shareSuccess) {
                tempShareData.shareSuccess()
              }
            },
            cancel: () => {}
          })
        } catch (e) {}
        try {
          wx.onMenuShareTimeline({
            title: tempShareData.title,
            link: tempShareData.link,
            imgUrl: tempShareData.imgUrl,
            success: function (res) {
              if (tempShareData.shareSuccess) {
                tempShareData.shareSuccess()
              }
            },
            cancel: function () {}
          })
        } catch (e) {}
      } else {
        try {
          wx.updateAppMessageShareData({
            title: tempShareData.title,
            desc: tempShareData.desc,
            link: tempShareData.link,
            imgUrl: tempShareData.imgUrl,
            success: res => {
              if (tempShareData.shareSuccess) {
                tempShareData.shareSuccess()
              }
            },
            cancel: () => {}
          })
        } catch (e) {}
        try {
          wx.updateTimelineShareData({
            title: tempShareData.title,
            link: tempShareData.link,
            imgUrl: tempShareData.imgUrl,
            success: function (res) {
              if (tempShareData.shareSuccess) {
                tempShareData.shareSuccess()
              }
            },
            cancel: function () {}
          })
        } catch (e) {}
      }
    })
  }
}
export default { state, getters, mutations, actions }
