export default [
  {
    path: 'iframe/:url',
    name: 'Iframe',
    component: () => import('@/views/iframe'),
    meta: {
      title: '尊创科技-联盟服务',
      tabBar: false,
      keepAlive: false
    }
  }
]
