import Vue from 'vue'
import Router from 'vue-router'
import routes from './routers'
import { getToken } from '@/utils/token'
import store from '../store'
import { delUrlParam, getUrlParam } from '@/utils/tools'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  /* eslint-disable */
  if (_hmt) {
    if (to.path) {
      _hmt.push(['_trackPageview', to.fullPath])
    }
  }
  store.dispatch('app/setPreRoute', from)
  if (!getToken()) {
    const data = await store.dispatch('user/wxGrant')
    if (!data) {
      return
    }
  }
  if (getUrlParam('code') && getUrlParam('state')) {
    let tempLocation = window.location.href
    tempLocation = delUrlParam(tempLocation, 'code')
    tempLocation = delUrlParam(tempLocation, 'state')
    window.location.replace(tempLocation)
    return
    // history.replaceState({}, '', tempLocation)
  }

  if (!store.getters['user/hasGetInfo']) {
    await store.dispatch('user/getUserInfo')
  }
  if (!store.getters['user/hasGetWxConfig']) {
    await store.dispatch('user/getWxConfig')
  }
  next()
})

/*
router.afterEach( async (to, from) => {
})
*/

export default router
