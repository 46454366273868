/**
 * 自定义常量格式转化
 * @param name
 * @param data
 */
export const constantFormat = (name, data) => {
  const final = {}
  final[name] = Object.assign({}, data)
  const tempObj = {}
  for (const key in data) {
    tempObj[data[key].value] = data[key]
  }
  final[`${name}Key`] = tempObj
  return final
}

export const isEmpty = (data) => {
  return data === '' || data === undefined || data === null
}

export const getHost = () => {
  let url = document.location.toString()
  let arrUrl = url.split('//')
  return `${arrUrl[0]}//${window.location.host}`
}

export const getUrlParam = (name, url) => {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  let tempUrl = url || window.location.search
  let r = tempUrl.substr(1).match(reg)
  if (r != null) return decodeURIComponent(r[2])
  return null
}

/**
 * url添加替换参数参数
 * @param url
 * @param paramName 参数名
 * @param replaceWith 参数值
 * @returns {string}
 */
export const addUrlParam = (url, paramName, replaceWith) => {
  if (url.indexOf(paramName) > -1) {
    // eslint-disable-next-line no-eval
    let re = eval('/(' + paramName + '=)([^&]*)/gi')
    url = url.replace(re, paramName + '=' + replaceWith)
  } else {
    let paraStr = paramName + '=' + replaceWith

    let idx = url.indexOf('?')
    if (idx < 0) {
      url += '?'
    } else if (idx >= 0 && idx !== url.length - 1) {
      url += '&'
    }
    url = url + paraStr
  }
  return url
}

export const delUrlParam = (url, ref) => {
  if (url.indexOf(ref) === -1) {
    return url
  }
  let tempUrl = ''
  let arrUrl = url.split('?')
  let base = arrUrl[0]
  let arrParam = arrUrl[1].split('&')
  let index = -1
  for (let i = 0; i < arrParam.length; i++) {
    let paired = arrParam[i].split('=')
    if (paired[0] === ref) {
      index = i
      break
    }
  }
  if (index === -1) {
    tempUrl = url
  } else {
    arrParam.splice(index, 1)
    tempUrl = base + '?' + arrParam.join('&')
  }
  if (/\?$/.test(tempUrl)) {
    tempUrl = tempUrl.substr(0, tempUrl.length - 1)
  }
  return tempUrl
}

/**
 * 判断是否ios
 * @returns {boolean}
 */
export const isIOS = () => {
  let u = navigator.userAgent
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}

/**
 * 滚动到顶部
 * @param target
 * @param speed 滚动时间
 */
export const scrollToTop = (target, speed = 500) => {
  const el = document.querySelector(target)
  const cubic = value => Math.pow(value, 3)
  const easeInOutCubic = value => value < 0.5
    ? cubic(value * 2) / 2
    : 1 - cubic((1 - value) * 2) / 2
  const beginTime = Date.now()
  const beginValue = el.scrollTop
  const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16))
  const frameFunc = () => {
    const progress = (Date.now() - beginTime) / speed
    if (progress < 1) {
      el.scrollTop = beginValue * (1 - easeInOutCubic(progress))
      rAF(frameFunc)
    } else {
      el.scrollTop = 0
    }
  }
  rAF(frameFunc)
}
