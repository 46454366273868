<template>
  <div class="main-container" ref="mainRef" v-bind:class="{ active: calcTabShow }">
    <keep-alive :include="cacheList">
      <router-view :key="key"/>
    </keep-alive>
    <van-tabbar route safe-area-inset-bottom v-model="tabsActive" v-show="calcTabShow">
      <van-tabbar-item :to="{name: 'Home'}" v-bind:class="{active: tabsActive === 0}">
        <img v-if="tabsActive === 0" slot="icon" src="../../assets/image/icon/icon_home_sel.png">
        <img v-else slot="icon" src="../../assets/image/icon/icon_home.png">
        首页
      </van-tabbar-item>
      <van-tabbar-item :to="{name: 'UserQuery'}" v-bind:class="{active: tabsActive === 1}">
        <img v-if="tabsActive === 1" slot="icon" src="../../assets/image/icon/icon_user_sel.png">
        <img v-else slot="icon" src="../../assets/image/icon/icon_user.png">
        我的
      </van-tabbar-item>
    </van-tabbar>
    <van-number-keyboard safe-area-inset-bottom />
  </div>
</template>
<script>
const GLOBAL_SCROLL = {}
export default {
  name: 'Main',
  data () {
    return {
      tabsActive: 0
    }
  },
  computed: {
    cacheList () {
      const _this = this
      let getCacheMenu = list => {
        let tempList = []
        for (let i in list) {
          if (list[i].meta && list[i].meta.keepAlive) {
            tempList.push(list[i].name)
          }
          if (list[i].children && list[i].children.length > 0) {
            tempList = [...tempList, ...getCacheMenu(list[i].children)]
          }
        }
        return tempList
      }
      const list = ['ParentView', ...getCacheMenu((_this.$router && _this.$router.options && _this.$router.options.routes) ? _this.$router.options.routes : [])]
      return list
    },
    key () {
      return this.$route.meta && this.$route.meta.keepAlive ? this.$route.path : `${this.$route.path}_${new Date().getTime()}`
    },
    calcTabShow () {
      return this.$route.meta && this.$route.meta.tabBar && [this.GLOBAL_CONSTANT.verifyStatus.CHECK.value, this.GLOBAL_CONSTANT.verifyStatus.WAITING.value].includes(this.GLOBAL_USER_DATA.status)
    }
  },
  watch: {
    $route: {
      handler (to, from) {
        const _this = this
        if (_this.$refs.mainRef) {
          if (from && from.meta && from.meta.keepAlive) {
            GLOBAL_SCROLL[from.fullPath] = {
              x: _this.$refs.mainRef.scrollLeft,
              y: _this.$refs.mainRef.scrollTop
            }
          }
          _this.$nextTick(() => {
            if (GLOBAL_SCROLL[to.fullPath]) {
              _this.$refs.mainRef.scrollTo(GLOBAL_SCROLL[to.fullPath].x, GLOBAL_SCROLL[to.fullPath].y)
            } else {
              _this.$refs.mainRef.scrollTo(0, 0)
            }
          })
        }
      },
      immediate: true
    },
    '$route.name': {
      handler () {
        this.switchActiveMenu()
      },
      immediate: true
    }
  },
  methods: {
    switchActiveMenu () {
      const _this = this
      switch (_this.$route.name) {
        case 'home':
          _this.tabsActive = 0
          break
        case 'UserQuery':
          _this.tabsActive = 1
          break
        default:
          _this.tabsActive = 0
      }
    }
  }
}
</script>
<style lang="scss">
@import './index.scss';
</style>
