import ParentView from '@/layouts/parentView'
import store from '@/store'
import constant from '@/constant'
export default [
  {
    path: 'user',
    name: 'User',
    component: ParentView,
    redirect: { name: 'UserQuery' },
    children: [
      {
        path: 'query',
        name: 'UserQuery',
        component: () => import('@/views/user'),
        meta: {
          title: '个人中心',
          tabBar: true,
          keepAlive: true
        }
      },
      {
        path: 'profile',
        name: 'UserProfile',
        component: () => import('@/views/user/profile'),
        meta: {
          title: '我的信息',
          tabBar: false,
          keepAlive: false
        }
      },
      {
        path: 'collect/:timestamp?',
        name: 'UserCollect',
        component: () => import('@/views/user/collect'),
        meta: {
          title: '我的收藏',
          tabBar: false,
          keepAlive: true
        }
      },
      {
        path: 'lock/:timestamp?',
        name: 'UserLock',
        component: () => import('@/views/user/lock'),
        meta: {
          title: '我的锁定',
          tabBar: false,
          keepAlive: true
        }
      },
      {
        path: 'area',
        name: 'UserArea',
        component: () => import('@/views/user/area'),
        meta: {
          title: '项目地区',
          tabBar: false,
          keepAlive: false
        }
      },
      {
        path: 'question',
        name: 'UserQuestion',
        component: () => import('@/views/user/question'),
        beforeEnter: (to, from, next) => {
          if ([constant.verifyStatus.CHECK.value, constant.verifyStatus.WAITING.value].includes(store.getters['user/status'])) {
            next()
          } else {
            next({ name: 'Home' })
          }
        },
        meta: {
          title: '常见问题',
          tabBar: false,
          keepAlive: true
        }
      }
    ]
  }
]
