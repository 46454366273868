module.exports = {
  // token名称
  tokenName: 'wx-notice-token',
  // token存储位置localStorage sessionStorage cookie
  storage: 'localStorage',
  qqmapsdkKey: 'JXPBZ-JNRKU-7I4VU-BK5NH-BUGD3-QIBG7',
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://api.wx.dev.xmzccm.cn'
      : 'https://wx.api.xmzccm.cn',
  contentType: 'application/json;charset=UTF-8',
  // 最长请求时间
  requestTimeout: 10000,
  // 操作正常code，支持String、Array、int多种类型
  successCode: [200],
  // 数据状态的字段名称
  statusName: 'code',
  // 状态信息的字段名称
  messageName: 'message'
}
