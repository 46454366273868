import request from '@/utils/request'

/**
 * 登录
 * @param data
 * @returns {*}
 */
export const loginByCode = (data) => {
  return request({
    url: '/api/app/wechatAuthorize',
    method: 'post',
    data: data
  })
}

/**
 * 获取微信code
 * @param callback 当前路径
 * @returns {*}
 */
export const wxAuth = (callback) => {
  return request({
    url: '/api/wechat/wxcode',
    method: 'get',
    params: { callback }
  })
}

/**
 * 获取用户信息
 * @returns {never}
 */
export const getUserInfo = () => {
  return request({
    url: '/api/user/info',
    method: 'get'
  })
}

/**
 * 获取微信配置
 * @param url
 * @returns {*}
 */
export const getWxConfig = (url) => {
  return request({
    url: '/api/app/wechatSignPackage',
    method: 'get',
    params: { url }
  })
}

/**
 * 更新用户信息
 * @param data
 *    加入合伙人: { company_name, company_user, phone, company_post, act: 'join' }
 *    更新项目区域: { act: 'area', area }
 * @param hiddenToast 是否隐藏loading
 * @returns {*}
 */
export const updateUser = (data, hiddenToast) => {
  return request({
    url: '/api/user/info',
    method: 'post',
    data: data,
    hiddenToast
  })
}
